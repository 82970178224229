import React from 'react'

const Cards = () => {
  return (
    <div className='w-full py-[10rem] px-8 bg-white'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-1 gap-8'>
            <div className='w-full shadow-xl shadow-gray-400 flex flex-col p-4 my-4 rounded-lg '>
                <h2 className='text-6xl font-bold text-center py-8'>Wishlist</h2>
                <div className='flex flex-col md:flex-row justify-center mb-8'>
                  <div className='flex flex-col p-8 mx-2 shadow-xl shadow-gray-300 rounded-lg items-center'>
                    <p className='line-through text-gray-400 text-center text-2xl'>62лв</p>
                    <p className='text-center text-4xl font-bold'>49лв</p>
                    <a href='https://buy.stripe.com/3cs9BZ09j4FBcdafZ0' target='_blank' rel='noreferrer' className='appearance-none text-center bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 hover:scale-105 duration-100'>Създай сега</a>
                  </div>
                  <div className='flex flex-col p-8 mx-2 shadow-xl shadow-gray-300 rounded-lg'>
                    <p className='line-through text-gray-400 text-center text-2xl'>32лв</p>
                    <p className='text-center text-4xl font-bold'>24лв</p>
                    <a href='https://buy.stripe.com/aEU8xV4pz7RNgtqcMN' target='_blank' rel='noreferrer' className='appearance-none text-center bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 hover:scale-105 duration-100'>Активирай</a>
                  </div>
                </div>
                <p className='text-center'>*Таксата от 49лв е за първоначално създаване. Всеки клиент, закупил Wishlist, го получава за 30 дни.</p>
                <p className='text-center'>*За всяко следващо активиране на услугата за 30 дни се заплаща сумата от 24лв.</p>
                <p className='text-center'>*След успешно заплащане, ще получите имейл с инструкции как да достъпите вашия wishlist.</p>
            </div>
            {/* <div className='w-full shadow-xl shadow-gray-400 flex flex-col p-4 my-4 rounded-lg '>
                <h2 className='text-2xl font-bold text-center py-8 text-white'>Покана</h2>
                <p className='line-through text-gray-400 text-center text-4xl'>80лв</p>
                <p className='text-center text-4xl font-bold text-white'>59лв</p>
                <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 hover:scale-105 duration-100'>Вземи сега</button>
            </div> */}
        </div>
    </div>
  )
}

export default Cards