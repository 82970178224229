import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Cards from './components/Cards'
import Wishlist from './components/Wishlist';
import Footer from './components/Footer';

function App() {
    return (
      <div>
        <Navbar />
        <Hero />
        <Cards />
        <Wishlist />
        <Footer />
      </div>
    );
  }

export default App;
