import React from 'react'
import AdminPic from '../AdminPic.png'
import UserPic from '../UserPic.png'

const Wishlist = () => {
  return (
    <div className=' py-[5rem] px-8 '>
        <p className='text-center text-5xl text-white'>Wishlist</p>
        <p className='w-[90%] text-xl text-center mx-auto py-[2rem] text-white'>Дигиталният Wishlist-Presentify, е иновативно приложение, което улеснява създаването и управлението на списъци с подаръци за различни поводи, които се споделят чрез QR-код с участниците на събитието. Съчетавайки административен панел за организатора на събитието и потребителски панел за участниците, Presentify предлага удобство и ефективност в процеса на подготовка и управление на списъците с подаръци.</p>
        <div className='max-w-[1240px] mx-auto grid px-8 md:grid-cols-2 gap-8'>
            <div className='w-full shadow-xl shadow-gray-300 flex flex-col md:flex-row p-4 my-4 rounded-lg '>
                <div className='mx-1'>
                    <h2 className='text-2xl font-bold text-center py-8 text-white'>Административен панел</h2>
                    <p className='text-white'><p className='font-bold text-white'>Създаване на поводи:</p> Администраторът има възможността да създаде различни поводи (рождени дни, сватби, коледни празници и други).</p>
                    <p className='text-white'><p className='font-bold text-white'>Добавяне на подаръци:</p> За всеки повод администраторът може да добавя подаръци, които биха били желани от лицето, което отбелязва събитието. Това включва възможността за описание и връзки към онлайн магазини, където подаръците могат да бъдат закупени.</p>
                    <p className='text-white'><p className='font-bold text-white'>Промяна на фон:</p> Администраторът може да персонализира визуалния аспект на Presentify, като променя фона на страницата, за да съответства на тематиката или настроението на събитието.</p>
                </div>
                    <img className='h-96 object-contain self-center pt-4' src={AdminPic} alt=''/>
            </div>
            <div className='w-full shadow-xl shadow-gray-300 flex flex-col md:flex-row p-4 my-4 rounded-lg '>
                <div className='mx-1'>
                    <h2 className='text-2xl font-bold text-center py-8 text-white'>Потребителски панел</h2>
                    <p className='text-white'><p className='font-bold text-white'>Отбелязване на закупени подаръци:</p>Участниците могат да маркират подаръците, които са вече закупени или планират да закупят, като така отбелязват какво вече е "заето" и избягват дублиране на подаръци.</p>
                    <p className='text-white'><p className='font-bold text-white'>Споделяне чрез QR-код:</p> Уникалният QR-код, свързан с всеки списък, улеснява споделянето на Presentify с участниците. Потребителите могат лесно да сканират QR-кода с мобилно устройство и да получат достъп до списъка с подаръци.</p>
                </div>
                <img className='h-96 object-contain self-center pt-4' src={UserPic} alt=''/>
            </div>
        </div>
        <p className='w-[90%] text-xl text-center mx-auto py-[2rem] text-white'>Presentify предлага удобство, персонализация и лесно управление както за администраторите, така и за участниците в събитията. Със своите функции и интуитивен интерфейс, той е идеален инструмент за организиране на различни поводи и обмен на подаръци.</p>
    </div>
  )
}

export default Wishlist