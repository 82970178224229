import React from 'react'
import { FaCcVisa, FaCcMastercard } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='max-w-[1240px] flex flex-col mx-auto py-16 px-4 text-gray-300 items-center text-center'>
        <div>
            <h1 className='w-full text-3xl font-bold text-[#00df9a]'>PRESENTIFY</h1>
            <p className='py-4 text-sm'>Copyright 2024 PRESENTIFY</p>
            <p className='text-sm'>Ако имате нужда от допълнителна информация,</p>
            <p className='text-sm'>не се колебайте да се свържете с нас на посочения имейл адрес:</p>
            <p className='py-4 text-sm'>info@presentify.bg</p>
            <div className='w-full flex items-center justify-center my-6'>
                <FaCcVisa size={30} />
                <FaCcMastercard size={30}/>
            </div>
        </div>
    </div>
  )
}

export default Footer