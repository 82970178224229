import React from 'react'
import {ReactTyped}  from 'react-typed'
import QRpic from '../QR.png'

const Hero = () => {
  return (
    <div className='text-white px-8'>
        <div className='max-w-[800px]  w-full h-screen mx-auto text-center flex flex-col justify-center'>
            <p className='text-white text-xl font-bold p-2'>Омръзна ли ви да не можете да измислите подаръци</p>
            <p className='text-white text-xl font-bold p-2'>или да получавате такива, които никога не ползвате? </p>
            <h1 className='text-white md:text-4xl sm:text-3xl text-2xl font-bold md:py-3'>Създадохме <p className='text-[#00df9a] text-5xl'>PRESENTIFY</p>за да променим това!</h1>
            <div className='flex justify-center items-center'>
                <p className='md:text-4xl sm:text-3xl text-xl font-bold py-4'>Използвай за</p>
                <ReactTyped className='md:text-4xl sm:text-3xl text-xl font-bold md:pl-4 pl-2' strings={['Рожден ден', 'Имен ден', 'Сватба', 'Коледа', 'Всеки повод']} typeSpeed={80} backSpeed={100} loop/>
            </div>
            <img className='h-96 object-contain self-center pt-4' src={QRpic} alt=''/>
            {/* <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>Поръчай сега</button> */}
        </div>
    </div>
  )
}

export default Hero