import React from 'react'
import Logo from '../Logo1.png'

const Navbar = () => {

  return (
    <div className='flex justify-between items-center h-16 mx-auto px-4 text-white'>
        {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>PRESENTIFY</h1> */}
        <img className='w-[100px] self-center pt-4' src={Logo} alt=''/>
    </div>
  )
}

export default Navbar